<template>
  <div>
    <vs-row>
      <vs-col vs-w="7" vs-xs="12">
        <div class="f-p5">
          <h1 class="color-blue title24 mb-0">
            Cotización: {{ quote.client.first_name }}
            {{ quote.client.last_name }}
          </h1>
          <p class="mt-2">
            <span>
              {{ quote.project.name }}, Unidad:
              {{ mainProperty.unit_number }}
            </span>
            <span class="ml-2 background-default" v-if="showChip">
              {{ chipLabel }}
            </span>
            <span
              class="background-default"
              v-if="propertyOptioned && quoteStatus"
            >
              Unidad {{ mainProperty.unit_number }} se encuentra en un estado
              {{ $tc("message.optioned", 1, user.countryCode) }}
            </span>
          </p>
        </div>
      </vs-col>

      <vs-col vs-w="5" vs-xs="12">
        <vs-row>
          <vs-col vs-w="6" class="f-p5">
            <vs-button
              type="button"
              class="green"
              v-if="activateActionButtons"
              @click="newReservation"
            >
              Crear {{ $tc("message.reservation", 1, user.countryCode) }}
            </vs-button>
          </vs-col>
          <vs-col vs-w="6" class="f-p5">
            <vs-button color="dark" type="border" @click="claseOptioned()">
              Cerrar
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <template v-if="quote">
      <vs-row>
        <vs-col vs-w="4" vs-xs="12" class="f-mt15">
          <div class="f-p5">
            <h3 class="title14">Datos de vendedor</h3>
            <vs-card>
              <table class="table">
                <tbody>
                  <tr>
                    <th>Nombre:</th>
                    <td>
                      {{ quote.user.first_name }} {{ quote.user.last_name }}
                    </td>
                  </tr>
                  <tr>
                    <th>Teléfono:</th>
                    <td>{{ quote.user.phone }}</td>
                  </tr>
                  <tr>
                    <th>Email:</th>
                    <td>
                      {{
                        quote.user.accounts.length
                          ? quote.user.accounts[0].email
                          : ""
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </vs-card>
            <h3 class="title14">Datos de prospecto</h3>
            <vs-card>
              <table class="table">
                <tbody>
                  <tr>
                    <th>Nombre:</th>
                    <td>
                      {{ quote.client.first_name }} {{ quote.client.last_name }}
                    </td>
                  </tr>
                  <tr>
                    <th>Teléfono:</th>
                    <td>{{ quote.client.phone }}</td>
                  </tr>
                  <tr>
                    <th>Email:</th>
                    <td>{{ quote.client.email }}</td>
                  </tr>
                </tbody>
              </table>
            </vs-card>
            <h3 class="title14">Datos de cotización</h3>
            <table class="table-quoter roud10 mb-4">
              <tbody>
                <tr>
                  <th><div class="blue-light">Precio total</div></th>
                  <td>
                    <div class="blue-light">{{ totaltofinance }}</div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div class="blue-light">
                      Enganche {{ quote.prj_downpayment_type_name }}
                      {{ quote.prj_downpayment_rate }}%
                    </div>
                  </th>
                  <td>
                    <div class="blue-light">{{ downpaymentAmount }}</div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div>
                      <small>
                        <strong
                          >{{
                            $tc("message.Reservation", 1, user.countryCode)
                          }}:
                        </strong>
                      </small>
                    </div>
                  </th>
                  <td>
                    <div>
                      <small>
                        {{ reservationAmount }}
                      </small>
                    </div>
                  </td>
                </tr>
                <tr v-if="quote.payment_promise_amount != ''">
                  <th>
                    <div>
                      <small
                        ><strong> PCV {{ quote.prj_pcv_rate }}%</strong></small
                      >
                    </div>
                  </th>
                  <td>
                    <div>
                      <small>{{ percentagepcv }}</small>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div class="blue-light">
                      Saldo {{ percentagefinanced }}%
                    </div>
                  </th>
                  <td>
                    <div class="blue-light">{{ balancetobefinanced }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <template v-if="pipeDriveData.length">
              <img
                src="/logopendrive-50x160.png"
                width="90"
                class="logo-pipidrive"
              />
              <vs-card>
                <table class="table">
                  <tbody>
                    <tr>
                      <th>Trato:</th>
                      <td>{{ pipeDriveData[0].title }}</td>
                    </tr>
                    <tr>
                      <th>Estado:</th>
                      <td>
                        <span :class="chipColorPipeDrive">{{
                          chipLabelPipeDrive
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Embudo / Etapa:</th>
                      <td>{{ pipeDriveData[0].stage.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </vs-card>
            </template>
          </div>
        </vs-col>

        <vs-col vs-w="8" vs-xs="12" class="f-mt15">
          <div class="f-p5">
            <div class="card mb-5">
              <iframe
                height="500px"
                width="100%"
                frameborder="0"
                v-if="pdfUrlLoaded"
                :src="pdfUrl"
              >
              </iframe>
            </div>
            <vs-row>
              <vs-col vs-w="4" vs-xs="12" class="f-p5">
                <vs-button type="border" color="dark" @click="openQuotePdf">
                  Descargar cotización
                </vs-button>
              </vs-col>
              <vs-col
                vs-w="4"
                vs-xs="12"
                class="f-p5"
                v-if="activateActionButtons"
              >
                <vs-button type="border" color="dark" @click="sendQuoteEmail">
                  Enviar cotización
                </vs-button>
              </vs-col>
              <vs-col
                vs-w="4"
                vs-xs="12"
                class="f-p5"
                v-if="
                  quote.project.allow_optioned &&
                  (quote.status === 'OPTIONED' || quote.status === 'OPEN') &&
                  (user.role === 'ADMIN' ||
                    user.role === 'SUPERVISOR' ||
                    user.role === 'SELLER')
                "
              >
                <vs-button
                  type="button"
                  class="btn btn-block yellow mb-3"
                  @click="openConfirmationModal()"
                >
                  {{ propertyOptioned ? "Quitar" : "Crear" }}
                  {{ $tc("message.optioned", 1, user.countryCode) }}
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
      </vs-row>

      <Popup
        :showPopup="visible"
        :title="`Unidad: ${mainProperty.unit_number}`"
        @close="closePopUp"
      >
        <img
          v-if="selectedImage"
          :src="selectedImage.image_url"
          slot="content"
          class="mt-4"
        />
      </Popup>
      <Popup
        :showPopup="createReservationErrorVisibility"
        :title="`Error creando la reserva: ${this.createReservationError}`"
        @close="closePopUpCreateError"
      >
      </Popup>
      <ConfirmationModal
        :propertySelected="[quote]"
        :msg="`${
          propertyOptioned
            ? '¿Esta seguro que desea liberar esta unidad?'
            : '¿Esta seguro que desea apartar esta unidad? Al hacerlo esta unidad ya no estara disponible para que asesores puedan utilizarla y cotizarla.'
        }`"
        :title="`${$tc('message.optioned', 1, user.countryCode)} de unidad`"
        baseColor="yellow"
        :statusForUpdate="quote.status"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { quotesApi } from "../services";
import { preFlightCreateReservation } from "../../reservations/services/reservation.api.js";
import pipedriveApi from "../../pipedrive/services/pipedrive.api";
import dayjs from "../../../helpers/days";
import { sentryCaptureException } from "../../../helpers/Sentry";
import ConfirmationModal from "../../optioned/components/ConfirmationModal.vue";
import { capitalize } from "../../../helpers/capitalize";
import Popup from "../../../components/Popup.vue";
import currencies from "../../../helpers/currencies";
import { email } from "../../../services";

const HANDLER_URL = process.env.VUE_APP_HANDLER_URL;

export default {
  name: "SingleQuote",
  props: ["quoteId"],
  components: {
    ConfirmationModal,
    Popup,
  },
  data() {
    return {
      quote: null,
      visible: false,
      quoteExpired: false,
      quoteStatus: false,
      showChip: false,
      chipLabel: "",
      chipColor: "",
      pdfUrl: "",
      pdfUrlLoaded: false,
      chipLabelPipeDrive: "",
      chipColorPipeDrive: "",
      pipeDriveData: [],
      propertyOptioned: false,
      mainProperty: null,
      selectedImage: null,
      swiperOption: {
        spaceBetween: 30,
        effect: "fade",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      createReservationErrorVisibility: false,
      createReservationError: "",
    };
  },
  async mounted() {
    this.$root.$on("reload-quote", async () => {
      await this.loadQuote();
    });
  },
  async beforeMount() {
    await this.loadQuote();
    setTimeout(() => {
      this.pdfUrl = `${HANDLER_URL}/quote/${this.quote.id}/pdf`;
      this.pdfUrlLoaded = true;
    }, 1000);

    const developerId = this.$store.getters["auth/getUser"].developerId;
    const { client } = this.quote;

    if (client.pipedrive_person_id && client.pipedrive_deal_id) {
      const dealId = client.pipedrive_deal_id;

      const pipedrive = await pipedriveApi.getPipedriveDeal(
        dealId,
        developerId
      );

      this.pipeDriveData = [pipedrive];

      this.validateQuoteStatusPipeDrive(this.pipeDriveData);
    }

    if (client.pipedrive_person_id && !client.pipedrive_deal_id) {
      const personId = this.quote.client.pipedrive_person_id;

      this.pipeDriveData = await pipedriveApi.getPipedriveDealsByPerson(
        personId,
        developerId
      );

      this.validateQuoteStatusPipeDrive(this.pipeDriveData);
    }
  },
  methods: {
    async loadQuote() {
      try {
        let quoteId = this.$route.params.id;
        if (this.quoteId) {
          quoteId = this.quoteId;
        }

        const resp = await quotesApi.GetSingleQuote(quoteId);
        this.quote = resp.data.quote_by_pk;
        this.mainProperty = this.getMainProperty(this.quote.quote_details);

        this.validateQuoteStatus(this.quote);

        if (this.quote.status == "RESERVED") {
          return;
        }

        this.validateExpirationDate(this.quote.expiration_date);

        if (!this.quoteExpired) {
          this.propertyOptioned =
            this.mainProperty.status === "OPTIONED" ? true : false;
        }
      } catch (e) {
        sentryCaptureException(e, "quote");
        return;
      }
    },
    openConfirmationModal() {
      this.$store.commit("optioned/modalConfirmationOptioned", true);
    },
    openQuotePdf() {
      const pdfUrl = `${HANDLER_URL}/quote/${this.quote.id}/pdf`;
      window.open(pdfUrl, "_blank");
    },
    sendQuoteEmail() {
      email.sendEmail("quotation", `{"quoteId": "${this.quote.id}"}`);
      this.$vs.notify({
        time: 6000,
        title: `Cotización Envida.`,
        text: `Se ha enviado la cotización correctamente.`,
        color: "success",
      });
    },
    parseDate(str) {
      return dayjs(str).format("DD/MM/YYYY");
    },
    showProjectUnitTypeImage(image) {
      this.visible = true;
      this.selectedImage = image;
    },
    validateExpirationDate(date) {
      const expirationDate = dayjs(date);
      const actualDate = dayjs();
      const difference = expirationDate.diff(actualDate, "days");
      if (difference < 0) {
        this.quoteExpired = true;
        this.showChip = true;
        this.chipLabel = "Cotización expirada";
        this.chipColor = "f-badge f-danger";
      }
    },
    validateQuoteStatus(quote) {
      switch (quote.status) {
        case "OPEN":
          this.showChip = false;
          this.quoteStatus = true;
          this.chipLabel = "Abierta";
          this.chipColor = "f-badge f-primary";
          break;
        case "OPTIONED":
          this.quoteStatus = true;
          this.showChip = true;
          this.chipColor = "f-badge f-warning";
          this.chipLabel = this.$tc(
            "message.optioned",
            1,
            this.user.countryCode
          );
          break;
        case "RESERVED":
          this.showChip = true;
          this.chipColor = "f-badge f-danger";
          this.chipLabel = this.$tc(
            "message.reservation",
            1,
            this.user.countryCode
          );
          break;
      }
    },
    validateQuoteStatusPipeDrive(pipedrive) {
      const status = pipedrive.length ? pipedrive[0].status : pipedrive.status;
      switch (status) {
        case "OPEN":
        case "open":
          this.chipLabelPipeDrive = "Abierta";
          this.chipColorPipeDrive = "badge badge-primary";
          break;
        case "OPTIONED":
        case "optioned":
          this.chipLabelPipeDrive = "badge badge-info";
          this.chipColorPipeDrive = "Opcionado";
          break;
        case "RESERVED":
        case "reserved":
          this.chipLabelPipeDrive = "badge badge-success";
          this.chipColorPipeDrive = "Reservada";
          break;
      }
    },

    async newReservation() {
      // first check if we can create a reservation
      let res;
      try {
        res = await preFlightCreateReservation(this.quote.id);
      } catch (e) {
        if (e.message.indexOf("Error:") !== -1)
          res = e.message.split("Error:")[1];
        else {
          sentryCaptureException(e, "creando_reserva");
          res =
            "El equipo ha sido notificado y se estara resolviendo el error lo antes posible";
        }
      }

      if (res === true) {
        this.$router.push(`/cotizacion/${this.quote.id}/reserva`);
      } else {
        this.createReservationError = res;
        this.createReservationErrorVisibility = true;
      }
    },
    claseOptioned() {
      this.$router.push(`/cotizaciones-en-proyecto/${this.quote.project.id}`);
    },
    getFormatCurrency(value) {
      const currencySymbol = this.$store.getters["project/currencySymbol"];

      return currencies.currencyFormatter({
        value,
        currency: currencySymbol,
      });
    },
    getTranslate(propertyTypeId) {
      const translate = this.$tc(
        `message.${capitalize(propertyTypeId.toLowerCase())}`,
        1,
        this.user.countryCode
      );

      if (!translate) return propertyTypeId;

      return translate;
    },
    getMainProperty(quoteDetail) {
      const mainPropertyFilter = quoteDetail.filter((property) => {
        return property.property.property_type_id !== "WAREHOUSE" &&
          property.property.property_type_id !== "PARKING"
          ? property.property
          : null;
      });

      return mainPropertyFilter.length ? mainPropertyFilter[0].property : null;
    },
    closePopUp() {
      this.visible = false;
    },
    closePopUpCreateError() {
      this.createReservationErrorVisibility = false;
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
    reservationAmount() {
      return this.getFormatCurrency(this.quote.reservation_amount);
    },
    downpaymentAmount() {
      return this.getFormatCurrency(this.quote.downpayment);
    },
    percentagepcv() {
      /* este valor debe de venir en el SDK*/
      return this.getFormatCurrency(
        (this.quote.total * this.quote.prj_pcv_rate) / 100
      );
    },
    totaltofinance() {
      return this.getFormatCurrency(this.quote.total);
    },
    projectUnitTypeImages() {
      const mainProperty = this.getMainProperty(this.quote.quote_details);

      if (!mainProperty.project_unit_type.project_unit_type_images.length) {
        return [];
      }

      return mainProperty.project_unit_type.project_unit_type_images;
    },

    balancetobefinanced() {
      return this.getFormatCurrency(this.quote.total_to_finance);
    },
    percentagefinanced() {
      return 100 - this.quote.prj_downpayment_rate;
    },
    activateActionButtons() {
      if (!this.quoteExpired && this.quoteStatus) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style>
.background-default {
  background-color: #dbeafe !important;
  color: black;
  font-weight: bold;
  padding: 6px;
  border-radius: 5px;
}
</style>
