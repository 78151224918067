import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function getPipedriveDealsByPerson(personId, developerId) {
  const response = await graphqlClient.query({
    query: gql`
      {
        getPipedriveDealsByPerson(personId: ${personId}, developerId:"${developerId}") {
          id
          title
          status
          person {
            name
            id
            email {
              label
              value
            }
            phone {
              label
              value
            }
          }
          organization {
            id
            name
          }
          pipeline {
            id
            name
          }
          stage {
            id
            name
          }
        }
      }
    `
  });
  return response.data.getPipedriveDealsByPerson;
}

async function getPipedriveDeal(dealId, developerId) {
  const response = await graphqlClient.query({
    query: gql`
      {
        getPipedriveDeal(id: ${dealId}, developerId:"${developerId}") {
          id
          title
          status
          person {
            name
            phone {
              label
              value
            }
            email {
              label
              value
            }
          }
          pipeline {
            id
            name
          }
          organization {
            id
            name
          }
          stage {
            id
            name
          }
        }
      }
    `
  });

  return response.data.getPipedriveDeal;
}


export default {
  getPipedriveDealsByPerson,
  getPipedriveDeal
};
