<template>
  <div class="reserva">
    <breadcrumb-component
      :pages="[{ pageName: `${project ? project.name : ''}`, route: `/cotizaciones-en-proyecto/${project ? project.id : ''}` },
      { pageName: `${ firstName } ${ lastName }, ${ propertyUnit }`, route:''}]"
    />
    <Quote />
  </div>
</template>

<script>
import BreadcrumbComponent from "../../../layouts/components/BreadcrumbComponent.vue";
import Quote from "../components/QuoteV2.vue";
import { api as projectApi } from "../../projects/services";
import { quotesApi } from "../services";

export default {
  components: {
    Quote,
    BreadcrumbComponent
  },
  data() {
    return {
      project: null,
      client: null,
      property: null,
    };
  },
  async mounted() {
    const projectId = this.$store.getters["project/currentProject"];
    this.project = await projectApi.get(projectId);

    const response = await quotesApi.GetSummaryQuote(this.$route.params.id);
    const quote = response.data ? response.data.quote : [];

    if(quote.length){
      this.client = quote[0].client;
      
      const filterProperty = quote[0].quote_details.filter(
            o =>
              o.property_type_id != "WAREHOUSE" || o.property_type_id != "PARKING"
          );

      if(!filterProperty.length) {
          this.$vs.notify({
            title: "Error al obtener información de la cotizacion",
            color: "danger"
          });

          return;
        }

      this.property = filterProperty[0].property
    }
  },
  computed: {
        firstName(){
            return this.client ? this.client.first_name.charAt(0).toUpperCase() + this.client.first_name.substring(1) : ''
        },
        lastName(){
            return this.client ?  this.client.last_name.charAt(0).toUpperCase() + this.client.last_name.substring(1) : ''
        },
        propertyUnit(){
            return  this.property ? this.property.unit_number : ''
        }
  }
};
</script>
